import React from "react";
import Typed from "react-typed";
import SEO from "../common/SEO";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import HeaderTwo from "../common/header/HeaderTwo";
import HeaderTopNews from "../common/header/HeaderTopNews";
import FooterTwo from "../common/footer/FooterTwo";
import Copyright from "../common/footer/Copyright";
import AboutOne from "../elements/about/AboutOne";
import ServiceFive from "../elements/service/ServiceFive";
import CalltoActionFive from "../elements/calltoaction/CalltoActionFive";
import TeamTwo from "../elements/team/TeamTwo";
import TestimonialThree from "../elements/testimonial/TestimonialThree";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import Mission from "../elements/about/Mission";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from "../data/blog/BlogList.json";
import Carousel from "./Carousels";
import Carousels from "./Carousels";
import HeaderTopBar from "../common/header/HeaderTopBar";
import "./Apps.css";
import { Carousel as C } from "antd";
import Timelines from "./Timelines";
import NewsArticle from "./NewsArticle";
var BlogListData = BlogClassicData.slice(0, 3);

const contentStyle = {
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
};

const BusinessConsulting2 = () => {
  const paragraphs = [
    {
      text: [
        "This is a WARNING to all buyers that would like to do business with",
        <b> Henan Lvyuan Water Treatment Technology</b>,
        " LTD (HENAN),  in China. ",
      ],
      image: null,
      alt: null,
    },
    {
      text: [
        "Members of",
        <b> this company </b>,
        "are",
        <b> dishonest and distrust worthy</b>,
        " and have ",
        <b>committed fraud </b>,
        "selling ",
        <b>chemicals</b>,
        " that are ",
        <b>diluted and defective</b>,
        ".  They have provided further provided forged testing documents to support their fraud!",
      ],
      image: null,
      alt: null,
    },
    {
      text: "Our company will present to you our experience with them so you understand our suffering, so you do not make the same mistakes we made.  ",
      image: null,
      alt: null,
    },
    {
      text: [
        <h3>A list of our damages:</h3>,

        "1) $45,000+ (initially it was $60,000 + ) of actual costs of Product and shipping/custom charges",
        <br />,
        "2) $30,000-$50,000  Profit",
        <br />,
        "3) Destruction of our reputation in the market!",
        <br />,
        "4) Liability for selling a fraudulent product to the public",
        <br />,
        "5)Having to dispose of a defective, dangerous product ",
      ],
      image: null,
      alt: null,
    },
    {
      text: " *All in all with loss of business in the coming months, we would estimate it to be over $250,000 of total losses that our company had to bear due to the fraud committed by this company.  The worst part is our reputation.  Many associated us with a company that was criminal that was trying to defraud the public.  There is not enough money to be able to clean up the stain that HENAN caused us in our market",
      image: null,
      alt: "Placeholder Image",
    },
    {
      text: " *The company admitted that they sold us a “Diluted” product but were only willing to give us approximately $15,000 in compensation due to the fear of Alibaba Trade assurance in which we will discuss in detail a bit later.  And that was after pleading with them for over 30 days and us having to accept terms from THEM(Ridiculous!).  They wanted to give us NOTHING but peanuts!  They could care less about our losses or the suffering that we went through.  This is a dishonest company that sent us fraudulent test reports and results and produced fraudulent labeling on the product WITH our company name and brand attached!  ",
      image: null,
      alt: "Placeholder Image",
    },

    {
      text: [
        <b>
          <u style={{ textAlign: "center" }}>Addendum 1: THE ORDER</u>
        </b>,
        <br />,
        "Our respectable company ordered 1 container of TCCA 90% chlorine from HENAN through Alibaba Trade Assurance as displayed below.  It clearly states that it is for TCCA 90!  This is the sales person who sold me this product.  ",
      ],
      image: "./images/orderEvents/1AlibabaOrder.jpg",
      alt: "Alibaba Order",
    },
    {
      text: [
        <b>
          <u>Addendum 2: THE CONTRACT</u>
        </b>,
        <br />,
        "This is the actual Contract.  Again it states exactly what we are going to purchase from this company. That it is TCCA 90% Chlorine.   They knew that they would not give is this product when they signed this contract it was later proven.",
      ],
      image: "./images/orderEvents/Addendum2.jpg",

      alt: "Contract with HENAN Lywater",
    },
    {
      text: [
        <b>
          <u>Addendum 3: The BILL OF LADING</u>
        </b>,
        <br />,
        "Again, here they state it is TCCA 90% when at the time they KNEW that it wasn’t the product that was loaded on the container.  Fraud and deception! ",
      ],
      image: "./images/orderEvents/addendum3.jpg",

      alt: "Bill of Lading by Henan Lywater",
    },
    {
      text: [
        <b>
          <u>Addendum 4: The TEST RESULT REPORT </u>
        </b>,
        <br />,
        "Again, here they state it is TCCA 90% when at the time they KNEW that it wasn’t the product that was loaded on the container.  Fraud and deception! ",
      ],
      image: "./images/orderEvents/Addendum4.jpg",

      alt: "Test Results Report from Henan Lywater",
    },

    {
      text: [
        <b>
          <u>Addendum 5: The LABELING:</u>
        </b>,
        <br />,
        "HERE is the label that they added on the product that was deceptive and fraudulent.  It put our company in peril legally as it was fraudulent- and they knew so. And WE DID NOT!  Defrauding our company and the public and putting us in legal jeopardy.  And worst yet, it was an advertisement so everyone can know later that we were associated with this Fraudulent product. ",
      ],
      image: "./images/orderEvents/Addendum5.jpg",

      alt: "Labeling By Henan Lywater",
    },
    {
      text: [
        <b>
          <u>Addendum 6: The FRAUD was DISCOVERED</u>
        </b>,
        <br />,
        "After bringing in items and selling 50% of it I started receiving complaints about the product that it was forming a slimy film on the water, the chlorine levels never went up and the PH only went up.  The tablets dissolved in a few hours instead of a few weeks and the chlorine level never went up after a couple of days.  After the first couple of complaints I was wondering if my customers were just trying to get discounts.  But after the 4th and 5th complaint I KNEW that there was something WRONG with the product.",
        <br />,
        "  Within hours, On social media they started advertising our brand and company as selling a fraudulent product to the public and we were threatened with legal action and requests to have all their money returned.  We contacted HENAN and for the first 2 days there was SILENCE from them.",
      ],
    },
    {
      text: [
        <b>
          <u>Addendum 7: OUR OWN TESTING = SGS</u>
        </b>,
        <br />,
        "We did our own SGS testing in the country of receipt and found that instead of being TCCA 90% it as 15.5%  - A worthless product filled with FILLER and CHEAP ingredients.  A blatant abuse and fraud committed by a company on ALIBABA!!",
      ],
      image: "./images/orderEvents/Addendum7.jpg",

      alt: "LyWater Our Company Testing",
    },
    {
      text: [
        <b>
          <u>Addendum 8: The COMPANY ADMITS to FRAUD</u>
        </b>,
        <br />,
        "We confronted them with proof, and THEY ADMITTED TO THEIR FRAUD- Please see email below from the “manager”.  Initially, they said that they would take care of us and make us Whole.  That is a LIE!  THEY ONLY OFFERED us a maximum of $10000 REFUND after OUR COMPANY LOST $60,000 and all subsequent profits that were made on this deal. This does not even include the liability and loss of reputation in our market. Yes, they took responsibility but did NOT offer compensation for our losses except a measly $15K! They actually had the nerve to give us terms so that we can get a small portion of the major losses that they caused us. ",
        <br />,
        "HENAN is a dishonest company and only provided minimal compensation because of our threats with informing Alibaba of their fraud.  Because the goods were later sent to Egypt, Alibaba does not have trade assurance GUARANTEES to that country.",
        <br />,
        "We attempted to still use this threat of Alibaba Trade Assurance to gain any type of compensation.  At the end we were given $15,000 as they refused to give any more!  That was only after 45 days of painful pleading and threats.    If Alibaba could assure us that they would not use the EGYPT factor against us, we would have not approved any type of settlement.  And it is a fact that if we didn’t have Alibaba Trade assurance, they would have ignored us and not given us a single dollar as compensation.",
      ],
      image: "./images/orderEvents/Addendum8.jpg",

      alt: "LyWater Our Company Testing",
    },
    {
      text: [
        <b>
          <u>
            Addenum Extra: Shirking Responsibility to compensate the damages
            that HENAN caused.{" "}
          </u>
        </b>,
      ],
      image: "./images/orderEvents/AddendumExtra.jpg",

      alt: "LyWater Our Company Testing",
    },
    {
      text: [
        <b>
          <u>
            Addendum 9: LETTER ADMITTING THEIR FRAUD To present to our
            customers.
          </u>
        </b>,
      ],
      image: "./images/orderEvents/Addendum9.png",

      alt: "LyWater Henan Letter to our customers",
    },
  ];

  return (
    <>
      <SEO title="Lvyuan Water Treatment Technology | LyWater Scam" />
      <main className="page-wrapper">
        {/* Start Slider Area  */}
        <div
          className="slider-area slider-style-1 bg-transparent height-650"
          style={{ paddingTop: "50px" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  <span
                    className="subtitle"
                    style={{ color: "red", fontWeight: "strong" }}
                  >
                    Lywater Henan Lvyuan Water Treatment Technology
                    <br /> Scam Alert
                  </span>
                  <h1 className="title display-two" style={{ color: "red" }}>
                    Henan Lvyuan Water Treatment Technology Scam <br />{" "}
                    <Typed
                      strings={["Fraud", "Thieves", "Con Artists"]}
                      typeSpeed={80}
                      backSpeed={5}
                      backDelay={1000}
                      loop
                    />
                  </h1>
                  <p className="description">
                    Save your company from being tarnished. <br />
                    Do not purchase from Henan Lvyuan Water Treatment
                    Technology. <br />
                    <b>Scam Websites:</b>
                    <br />
                    <a
                      style={{ color: "red", fontWeight: "bold" }}
                      href="https://hnlykj.en.alibaba.com/"
                    >
                      https://hnlykj.en.alibaba.com/
                    </a>
                    <br />
                    <a
                      style={{ color: "red", fontWeight: "bold" }}
                      href="http://www.hnlywater.com/"
                    >
                      http://www.hnlywater.com/
                    </a>
                    <br />
                    <a
                      style={{ color: "red", fontWeight: "bold" }}
                      href="https://www.lywater.com/"
                    >
                      https://www.lywater.com/
                    </a>
                    <br />
                    See Details Below.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}
        <Separator />
        {/* Start About Area  */}
        <AboutOne
          info={
            <div>
              <p>
                Lywater or Henan Lvyuan Water Treatment Technology is a
                manufacturing company that is based out of China and retails
                their products and services on Alibaba.
                <br />
                What they do not tell you, is their products are backed by
                fraudulent inspections, and their quality is subpar.
                <br />
                Many customers that have purchased through Lywater via their
                website, and on Alibaba have come forward with complaints that
                the products that were sold were not what was advertised.
              </p>
            </div>
          }
          title="About Henan Lvyuan Water Treatment Technology"
          image="https://scontent-lga3-1.xx.fbcdn.net/v/t39.30808-6/300701885_395243982762097_4683972514078504698_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=N7aE4QLpwUUAX-i_zFX&_nc_ht=scontent-lga3-1.xx&oh=00_AfDclURocKnFClXosMvBIS9FnYKIjqB8dVX3Yze_JdQEAQ&oe=63C25EA7"
        />
        {/* End About Area  */}

        <Separator />

        <h3 style={{ textAlign: "center" }}>Timeline of Events</h3>
        {/* Start About Area  */}
        <NewsArticle paragraphs={paragraphs} />
        {/*<Timelines />*/}

        {/* End About Area  */}

        <Separator />
        <div className="rn-service-area " id="whatisthis">
          <div className="container">
            <div className="row">
              <div className="col-lg-12"></div>
            </div>
            <Carousels />
          </div>
        </div>
        <Separator />
        <div style={{ marginBottom: "2rem" }} />

        <Copyright />
      </main>
    </>
  );
};
export default BusinessConsulting2;
