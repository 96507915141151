import React, { useState } from "react";

import { Button, Modal } from "antd";

function NewsArticle({ title, paragraphs }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalImg, setModalImg] = useState("");

  const openModal = (imgSrc) => {
    setModalOpen(true);
    setModalImg(imgSrc);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalImg("");
  };

  return (
    <div className="news-article-container">
      <h2 className="news-article-title">{title}</h2>
      {paragraphs.map((p, i) => (
        <div key={i} className="news-article-paragraph">
          {p.image && (
            <div>
              <label
                style={{
                  margin: "auto",
                  padding: "auto",
                  textAlign: "center",
                  display: "block",
                }}
              >
                Click image to Enlarge
              </label>
              <img
                src={p.image}
                style={{
                  width: "30vw",
                  float: "center",
                  display: "block",
                  margin: "auto",
                  padding: "auto",
                }}
                alt={p.alt}
                className="news-article-image-thumbnail"
                onClick={() => openModal(p.image)}
              />
            </div>
          )}
          <p className="news-article-text">{p.text}</p>
          <hr />
        </div>
      ))}

      <Modal open={modalOpen} onOk={closeModal} onCancel={closeModal}>
        <img src={modalImg} alt="" style={{ width: "100%" }} />
      </Modal>
    </div>
  );
}

export default NewsArticle;
